export const OFFER_STATUS: any = {
    on_wait: 'En espera',
    negotiating: 'Negociando',
    rejected: 'Rechazada',
    inactive: 'Inactiva',
    credit_analysis: 'En análisis de crédito',
    accepted: 'Aceptada',
    signature: 'En proceso de firma', // in signature process
    finished: 'Finalizada', // landlord and tenant signed the contract and offer is finished
    rejected_by_contract: 'Rechazada por contrato',
}