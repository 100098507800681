import React, { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { Option } from '../../../models/formModel';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { LandlordRegistrationForm } from '../models/landlordModel';
import { useForm } from 'react-hook-form';
import { getCountriesEndpoint, getStatesEndpoint } from '../../../services/publicService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { createLandlordEndpoint } from '../services/landlordService';
import useUserCountry from '../../../hooks/useUserCountry';

const useLandlord = () => {
    // TODO add country config to form
  const navigate = useNavigate();
  const { getPhoneCountryConfiguration } = useUserCountry();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const phone_config = getPhoneCountryConfiguration()
  const [error, setError] = useState('');
  const [countries, setCountries] = useState<Array<any>>([]);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [currentCountry, setCurrentCountry] = useState<Option>({
    label: 'Selecciona...',
    value: '',
  });
  const [states, setStates] = useState<Array<any>>([]);
  const [loadingStates, setLoadingStates] = useState<boolean>(true);
  const [currentState, setCurrentState] = useState<Option>({
    label: 'Selecciona...',
    value: '',
  });
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<LandlordRegistrationForm>({
    resolver: yupResolver(
      yup
        .object({
          first_name: yup.string().required('Nombre es requerido'),
          last_name: yup.string().required('Apellido es requerido'),
          email: yup
            .string()
            .email('Ingrese un formato valido para correo')
            .required('Correo electrónico es requerido'),
          phone: yup.string().required('Teléfono es requerido'),
          country: yup.string().required('País es requerido'),
          state: yup.string().required('Departamento es requerido'),
        })
        .required(),
    ),
  });

  useEffect(() => {
    const callCountries = async () => {
      const countries = await callEndpoint(getCountriesEndpoint());
      if (countries.status !== 200) {
        setError(
          'Ocurrio un error al momento de cargar los catalogos de paises',
        );
        return;
      }
      const countriesResponse = fetchResponseAdapter(countries.data);
      setCountries(
        listToSelectOptionAdapter(
          countriesResponse.data,
          'country_id',
          'country_name',
        ),
      );
      setLoadingCountries(false);
      setLoadingStates(false);
    };
    callCountries().catch((e) => console.error(e));
  }, []);

  const handleChangeCountry = async (selected: any) => {
    const { value } = selected;
    setStates([]);
    if (value) {
      const { status, data } = await callEndpoint(getStatesEndpoint(value));
      if (status === 200) {
        setCurrentCountry(selected);
        const statesResponse = fetchResponseAdapter(data);
        setStates(
          listToSelectOptionAdapter(
            statesResponse.data,
            'state_id',
            'state_name',
          ),
        );
        return;
      }
      setError(
        'Ocurrio un error al momento de cargar los catalogos de departamentos',
      );
    }
  };

  const doCreate = async (form: LandlordRegistrationForm) => {
    setError('');
    form.username = form.email;
    form.area_code = phone_config?.area_code;
    const { status, data } = await callEndpoint(
      createLandlordEndpoint(form, token),
    );
    if (status === 201) {
      return navigate('/landlord/view');
    }
    const response = fetchResponseAdapter(data);
    setError(response.message);
  };

  return {
    handleSubmit,
    doCreate,
    error,
    control,
    errors,
    loadingCountries,
    currentCountry,
    countries,
    setValue,
    handleChangeCountry,
    loadingStates,
    currentState,
    states,
    isLoading,
    phone_config,
  }

};

export default useLandlord;
