import React from 'react'

import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const AuthFooter = () => {
    const date = new Date()
    return (
        <>
            <footer className="py-5" style={{ backgroundColor: '#252B5C' }}>
                <Container>
                    <Row className="row">
                        <Col className='col-lg justify-content-center text-center'>
                            <span className="text-white">Propi @{ date.getFullYear() }</span>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default AuthFooter
