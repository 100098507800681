/**
 * Function to adapt any list to a select option structure { label, value }
 * @param list - any array of object list
 * @param keyIndex - name of property to extract index of new list
 * @param keyValue - name of property to extract value of new list
 * @returns
 */
export const listToSelectOptionAdapter = (
  list: Array<any>,
  keyIndex: string,
  keyValue: string,
  addDisabled: boolean = false,
  keyDisabled: string = '',
) => {
  let response = [];
  for (const element of list) {
    const value = element[keyIndex];
    const label = element[keyValue];
    let obj = { label, value, disabled: false };
    if (addDisabled && keyDisabled) {
      const isDisabled = element[keyDisabled];
      if (isDisabled) obj.disabled = true;
    }
    response.push(obj);
  }
  return response;
};

export const plainListToSelectOptionAdapter = (list: Array<any>) => {
  let response = [];

  for (const item of list) {
    let obj = {
      label: item,
      value: item,
    };
    response.push(obj);
  }

  return response;
};
