import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import Select from 'react-select';
import useLandlord from './hooks/useLandlord';
import TimelineHeader from '../../components/headers/TimelineHeader';

const LandlordCreate = () => {
  const {
    handleSubmit,
    doCreate,
    error,
    control,
    errors,
    loadingCountries,
    currentCountry,
    countries,
    setValue,
    handleChangeCountry,
    loadingStates,
    currentState,
    states,
    isLoading,
    phone_config
  } = useLandlord();

  return (
    <>
      <TimelineHeader
        name="Crear propietario"
        parentName="Lista de propietarios"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <Form role="form" onSubmit={handleSubmit(doCreate)}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Crear propietario</h3>
                </CardHeader>
                <CardBody>
                  <Col md="6">
                    {/* first_name */}
                    <Row>
                      <Col md="12">
                        {error && (
                          <UncontrolledAlert color="danger" fade={false}>
                            <span className="alert-inner--text">
                              <strong> Ops! </strong>
                              {error}
                            </span>
                          </UncontrolledAlert>
                        )}
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first_name"
                          >
                            Nombres
                          </label>
                          <Controller
                            name="first_name"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.first_name?.message && 'is-invalid'
                                }
                                {...field}
                                id="first_name"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.first_name?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* last_name */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="last_name"
                          >
                            Apellidos
                          </label>
                          <Controller
                            name="last_name"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.last_name?.message && 'is-invalid'
                                }
                                {...field}
                                id="last_name"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.last_name?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* email */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Correo electrónico
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.email?.message && 'is-invalid'
                                }
                                {...field}
                                id="email"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.email?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* country */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="country"
                          >
                            País
                          </label>
                          {!loadingCountries && (
                            <Controller
                              name="country"
                              control={control}
                              defaultValue={currentCountry.value || ''}
                              render={({ field: { ref } }) => (
                                <Select
                                  placeholder="Selecciona una ciudad"
                                  options={countries}
                                  defaultValue={currentCountry}
                                  onChange={(selected: any) => {
                                    const { value = '' } = selected;
                                    setValue('country', value);
                                    handleChangeCountry(selected);
                                  }}
                                  ref={ref}
                                />
                              )}
                            />
                          )}
                          <h5 className="text-danger">
                            {errors.country?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* state */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Departamento
                          </label>
                          {!loadingStates && (
                            <Controller
                              name="state"
                              control={control}
                              defaultValue={currentState.value || ''}
                              render={({ field: { ref } }) => (
                                <Select
                                  placeholder="Selecciona una ciudad"
                                  options={states}
                                  defaultValue={currentState}
                                  onChange={(selected: any) => {
                                    const { value = '' } = selected;
                                    setValue('state', value);
                                  }}
                                  ref={ref}
                                />
                              )}
                            />
                          )}
                          <h5 className="text-danger">
                            {errors.state?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* phone */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="phone">
                            Teléfono
                          </label>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    { phone_config?.area_code }
                                  </span>
                                </div>
                                <Input
                                  className={
                                    errors.phone?.message && 'is-invalid'
                                  }
                                  {...field}
                                  maxLength={ phone_config?.phone_length ?? 0 }
                                  id="phone"
                                />
                              </div>
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.phone?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
                <CardFooter>
                  <Button color={'primary'} disabled={isLoading}>
                    Crear
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default LandlordCreate;
