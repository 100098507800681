import { TimelineItem } from '../../../models/timelineModel';
import { OfferLog } from '../models/offerModel';
import { OFFER_STATUS } from '../../../constants/offerConstants';

export const getOfferStatusName = (status: string) => {
  return OFFER_STATUS[status] || 'NA';
};

export const offerLogTimelineAdapter = (
  offerLog: OfferLog[],
): TimelineItem[] => {
  return offerLog.map((offerLogItem): TimelineItem => {
    const {
      comment = 'Comentario del inquilino',
      created_at,
      trigger_by,
      current_status,
      previous_status,
      current_offer_price,
      previous_offer_price,
    } = offerLogItem;
    const hasChangeStatus = current_status !== previous_status;
    const hasChangePrice = current_offer_price !== previous_offer_price;
    let subMessage = '';
    if (hasChangeStatus) {
      subMessage = `Estado anterior: ${getOfferStatusName(
        previous_status,
      )}, Nuevo estado: ${getOfferStatusName(current_status)}`;
    }
    if (hasChangePrice) {
      subMessage += ` | Precio anterior: ${previous_offer_price}, Nuevo precio: ${current_offer_price}`;
    }
    return {
      header: comment,
      main_message: 'Cambio en oferta',
      message: comment,
      sub_message: subMessage,
      type: trigger_by,
      time: created_at,
    };
  });
};
