import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { InvestorTransactionDetail } from '../models/investors';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';

interface Props {
  transactionId: string;
  items: InvestorTransactionDetail[];
}

const InvestFuturePayments = ({ items, transactionId }: Props) => {
  const itemsMapped = items.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/investors/payments/${transactionId}/quota/${item.id}`,
    allow_divide_quota: item.allow_divide_quotas,
  }));
  return (
    <PaymentsDetailsTable
      transactionId={transactionId}
      title="Detalle de cuotas"
      items={itemsMapped}
    />
  );
};

export default InvestFuturePayments;
