// reactstrap components
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Alert,
} from "reactstrap";
import { useState } from "react";

// core components
import AuthFooter from './components/AuthFooter';
import { LoginForm } from "./models/loginModel";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { loginEndpoint } from "./services/authService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { getAuthUserAdapter } from "../../adapters/authUserAdapter";
import { mapApiCodeMessage } from "./adapters/responseMessageAdapter";
import logo from "../../assets/img/icons/common/iso_logo.svg";
import useAuth from '../../hooks/useAuth';
import useUserCountry from "../../hooks/useUserCountry";

const Auth = () => {

  const { setAuth } = useAuth()
  const [loginError, setLoginError] = useState<string>("");
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const navigate = useNavigate()
  const { setCountry } = useUserCountry()
  const {
    handleSubmit, formState: { errors }, control
  } = useForm<LoginForm>({
    resolver: yupResolver(
      yup.object({
        username: yup.string().required("Usuario es requerido"),
        password: yup.string().required("Contraseña es requerida"),
      }).required()
    ),
  });

  const doLogin = async (form: LoginForm) => {
    setLoginError("");
    const { username, password } = form
    const {
      status, data
    } = await callEndpoint(loginEndpoint(username, password));
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      const responseData = response.data;
      const countries = responseData.assigned_countries || []
      const assignedRoles = responseData.assigned_roles || []
      if(!countries.length){
        setLoginError(`Error (0): Usuario no tiene configrado países para acceder`);
        return;
      }
      if(!assignedRoles.length){
        setLoginError(`Error (0): Usuario no tiene roles/permisos para acceder`);
        return;
      }
      const authData = getAuthUserAdapter({
        first_name: responseData.name,
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        realm: responseData.realm,
        avatar: responseData.avatar,
        assigned_countries: responseData.assigned_countries,
        assigned_roles: responseData.assigned_roles
      });
      // TODO add assigned countries, 
      // TODO Encrypt data
      // current_country: responseData.assigned_countries[0]
      setCountry(responseData.assigned_countries[0])
      setAuth(authData)
      return navigate("/")
    }
    const { code } = data;
    const message = mapApiCodeMessage(code);
    setLoginError(`Error (${code}): ${message}`);
  }

  return (
    <>
      <div className="main-content" style={{ backgroundColor: '#252B5C' }}>
        {/* <AuthNavbar /> */}
        <div className="header py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-4">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img src={logo} alt="Logo PropiLatam" className="mb-3" />
                  <h1 className="text-white">Administrador</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5 pt-5">
                  {loginError && (
                    <Alert color="danger">
                      {loginError}
                    </Alert>
                  )}
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Iniciar con credenciales</small>
                  </div>
                  <Form noValidate role="form" onSubmit={handleSubmit(doLogin)}>
                    <FormGroup className="mb-2">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                          name="username"
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Email"
                              autoComplete="new-email"
                            />
                          )}
                        />
                      </InputGroup>
                    </FormGroup>
                    {errors.username?.message && (
                      <Alert color="danger">
                        {errors.username?.message}
                      </Alert>
                    )}
                    <FormGroup className="mb-2">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                          name="password"
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Password"
                              type="password"
                              autoComplete="new-password"
                            />
                          )}
                        />
                      </InputGroup>
                    </FormGroup>
                    {errors.password?.message && (
                      <Alert color="danger">
                        {errors.password?.message}
                      </Alert>
                    )}
                    <div className="custom-control custom-control-alternative custom-checkbox">

                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4" color="primary">
                        Iniciar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
}

export default Auth