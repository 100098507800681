import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};

export const acceptOfferEndpoint = (offerId: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) => axios({
      method: 'put',
      url: `${urlService}/ts/v1/admin/properties/offers/${offerId}/accept`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }),
    controller,
  };
};

export const createContractExpressEndpoint = (
  offerId: string,
  hasRentalManagement: boolean,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) => axios({
      method: 'post',
      url: `${urlService}/ts/v1/admin/contracts-express`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        offer_id: offerId,
        has_rental_management: hasRentalManagement,
      }),
    }),
    controller,
  };
};
