import React from 'react';
import { InvestorProjectDetail } from '../models/investors';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { Badge } from 'reactstrap';

export type Props = {
  project: InvestorProjectDetail;
};

const InvestorProjectActions: React.FC<Props> = ({ project }) => {
  const fieldMapper = [
    {
      title: <><i className="fa fa-solid fa-plus mr-2" /> Agregar nuevo modelo al proyecto</>,
      value: "",
      href: `/investors/projects/${project.id}/models/create`,
    },
    {
      title: <><i className="fa fa-duotone fa-image mr-2" /> Agregar recursos al proyecto</>,
      value: "",
      href: `/investors/projects/${project.id}/upload-images`,
    },
    {
      title: <><i className="fa fa-key fa-key mr-2" /> Administrar privacidad de proyecto</>,
      value: "",
      href: `/investors/projects/${project.id}/access`,
    },
    {
      title: <><i className="fa fa-calendar fa-calendar mr-2" /> Administrar asesorías del proyecto</>,
      value: "",
      href: `/investors/projects/${project.id}/visits`,
    },  
    {
      title: <><i className="fa-solid fa-house-circle-check mr-2"></i> Administrar unidades del proyecto</>,
      value: "",
      href: `/investors/projects/${project.id}/units`,
    }
  ]
  return (
    <AdminListGroup title={"Administrar proyecto"} items={fieldMapper}></AdminListGroup>
  );
};

export default InvestorProjectActions;