import React, { useCallback } from 'react';
import { TransactionDetailItem } from '../models/contracts';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';

export type ContractFuturePaymentsProps = {
  transactionItems: Array<TransactionDetailItem>;
};

const ContractFuturePayments: React.FC<ContractFuturePaymentsProps> = ({
  transactionItems,
}) => {
  const itemsMapped = transactionItems.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/contracts/${item.contractId}/quota/${item.id}`,
  }));
  return (
    <PaymentsDetailsTable
      title="Detalle de cuotas por pagar"
      items={itemsMapped}
    />
  );
};

export default ContractFuturePayments;
