import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  getLandlordEndpoint,
  updateLandlordBankInfoEndpoint,
  updateLandlordEndpoint,
} from './services/landlordService';
import TimelineHeader from '../../components/headers/TimelineHeader';
import { LandlordDetailData } from './models/landlordModel';
import { getAge } from '../../helpers/dateHelpers';
import DetailLabelText from '../properties/components/DetailLabelText';
import { getBankType } from './helpers/bankHelpers';
import { DEFAULT_AVATAR } from '../../constants/profileConstants';
import AdminShowEdit from '../../components/admin/AdminShowEdit';

const LandlordDetail = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [landlordData, setLandlordData] = useState<LandlordDetailData>();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate();

  useEffect(() => {
    const loadTenant = async () => {
      const { status, data } = await callEndpoint(
        getLandlordEndpoint(id, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setLandlordData(response.data);
      }
    };
    loadTenant().catch((e) => console.error(e));
  }, []);

  const updateLandlord = useCallback(
    async (payload: any) => {
      const { status } = await callEndpoint(
        updateLandlordEndpoint(id, payload, token),
      );

      if (status === 200) {
        navigate(0);
      }
    },
    [id, token, callEndpoint, navigate],
  );

  const updateLandlordBankInfo = useCallback(
    async (payload: any) => {
      const { status } = await callEndpoint(
        updateLandlordBankInfoEndpoint(id, payload, token),
      );

      if (status === 200) {
        navigate(0);
      }
    },
    [id, token, callEndpoint, navigate],
  );

  const goToDocuments = (profileId: string) => {
    navigate(`/landlord/${profileId}/documents`, { replace: true });
  };

  if (!landlordData || isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  return (
    <>
      <TimelineHeader
        name="Detalle de propietario"
        parentName="Listado de propietario"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center mb-4">
              <Col className="col-12"></Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {/* Profile card section */}
              <Col className="order-xl-2 pt-10" xl="4">
                <Card className="card-profile">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={
                              landlordData?.landlord.profile.avatar
                                ? landlordData?.landlord.profile.avatar
                                : DEFAULT_AVATAR
                            }
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between"></div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            {/* <Badge className="badge-lg" color="success">
                              Activo
                            </Badge> */}
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <h5 className="h3">
                          {landlordData.landlord.profile.first_name +
                            ' ' +
                            landlordData.landlord.profile.last_name +
                            '' +
                            (landlordData.landlord.profile.birth_date
                              ? `, ${getAge(
                                  landlordData.landlord.profile.birth_date,
                                )}`
                              : '')}
                        </h5>
                        {}
                        <AdminShowEdit
                          displayComponent={
                            <div className="font-weight-300">
                              Nombres:{' '}
                              {landlordData.landlord.profile.first_name}
                            </div>
                          }
                          ogValue={landlordData.landlord.profile.first_name}
                          ogPlaceholder={'Nombres'}
                          onEdit={(value: string) => {
                            const payload = {
                              name: value,
                            };
                            updateLandlord(payload);
                          }}
                        />
                        <AdminShowEdit
                          displayComponent={
                            <div className="font-weight-300">
                              Apellidos:{' '}
                              {landlordData.landlord.profile.last_name}
                            </div>
                          }
                          ogValue={landlordData.landlord.profile.last_name}
                          ogPlaceholder={'Apellidos'}
                          onEdit={(value: string) => {
                            const payload = {
                              last_name: value,
                            };
                            updateLandlord(payload);
                          }}
                        />
                        <AdminShowEdit
                          displayComponent={
                            <div className="font-weight-300">
                              Teléfono: {landlordData.landlord.profile.phone}
                            </div>
                          }
                          onEdit={(value: string) => {
                            const payload = {
                              phone: value,
                            };
                            updateLandlord(payload);
                          }}
                        />
                        <div className="mt-2 font-weight-300">
                          Correo: {landlordData.landlord.profile.email}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* Aditional info */}
              <Col className="order-xl-1" xl="8">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Información adicional</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                        {landlordData.landlord.profile.enabled ? (
                          <Badge className="badge-lg" color="success">
                            Perfil Activo
                          </Badge>
                        ) : (
                          <Badge className="badge-lg" color="danger">
                            Perfil Inactivo
                          </Badge>
                        )}
                        {landlordData.landlord.profile.is_account_verified ? (
                          <Badge className="badge-lg" color="success">
                            Correo verificado
                          </Badge>
                        ) : (
                          <Badge className="badge-lg" color="danger">
                            Correo no verificado
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="">
                      <DetailLabelText
                        label="Categoria"
                        text={
                          landlordData.landlord.category?.category ||
                          'No disponible'
                        }
                      />
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Información bancaria
                    </h6>
                    <div className="">
                      {/*<AdminShowEdit*/}
                      {/*  displayComponent={(*/}
                      {/*    <DetailLabelText*/}
                      {/*      label="Banco" text={landlordData.landlord.bank?.bank_name || 'No disponible'} />*/}
                      {/*  )}*/}
                      {/*  ogPlaceholder="Banco"*/}
                      {/*  onEdit={(value: string) => {*/}
                      {/*    const payload = {*/}
                      {/*      bank_id: value,*/}
                      {/*    };*/}
                      {/*    updateLandlordBankInfo(payload)*/}
                      {/*  }}*/}
                      {/*/>*/}
                      <DetailLabelText
                        label="Banco"
                        text={
                          landlordData.landlord.bank?.bank_name ||
                          'No disponible'
                        }
                      />
                      <AdminShowEdit
                        displayComponent={
                          <DetailLabelText
                            label="Número de cuenta"
                            text={
                              landlordData.landlord.bank_account ||
                              'No disponible'
                            }
                          />
                        }
                        ogPlaceholder="Número de cuenta"
                        onEdit={(value: string) => {
                          const payload = {
                            bank_account: value,
                          };
                          updateLandlordBankInfo(payload);
                        }}
                      />
                      <AdminShowEdit
                        displayComponent={
                          <DetailLabelText
                            label="Tipo de cuenta"
                            text={
                              landlordData.landlord.bank_account_type
                                ? getBankType(
                                    landlordData.landlord.bank_account_type,
                                  )
                                : 'No disponible'
                            }
                          />
                        }
                        ogPlaceholder="Tipo de cuenta"
                        onEdit={(value: string) => {
                          const payload = {
                            bank_account_type: value,
                          };
                          updateLandlordBankInfo(payload);
                        }}
                      />
                      <AdminShowEdit
                        displayComponent={
                          <DetailLabelText
                            label="Titular de cuenta"
                            text={
                              landlordData.landlord.bank_headline ||
                              'No disponible'
                            }
                          />
                        }
                        ogPlaceholder="Titular de la cuenta"
                        onEdit={(value: string) => {
                          const payload = {
                            bank_headline: value,
                          };
                          updateLandlordBankInfo(payload);
                        }}
                      />
                      <AdminShowEdit
                        displayComponent={
                          <DetailLabelText
                            label="Empresa o persona natural"
                            text={
                              landlordData.landlord.company_name ||
                              'No disponible'
                            }
                          />
                        }
                        ogPlaceholder="Empresa o persona natural"
                        onEdit={(value: string) => {
                          const payload = {
                            company_name: value,
                          };
                          updateLandlordBankInfo(payload);
                        }}
                      />
                    </div>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6 className="heading-small text-muted">Documentos</h6>
                      <Button
                        color="primary"
                        onClick={() =>
                          goToDocuments(landlordData?.landlord?.profile?.id)
                        }
                      >
                        Administrar documentos
                      </Button>
                    </div>

                    <div className="">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Tipo Documento</th>
                            <th scope="col">Número</th>
                            <th scope="col">Creado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {landlordData.documents &&
                            landlordData.documents.length > 0 &&
                            landlordData.documents.map((el) => (
                              <tr key={el.id}>
                                <td>{el.document.document}</td>
                                <td>{el.document_number}</td>
                                <td>{el.created_at_local}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <hr className="my-4" />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default LandlordDetail;
