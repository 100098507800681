import React, { useCallback, useEffect, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import { Col, Row } from 'reactstrap';
import ContractDetails from './components/ContractDetails';
import ContractFuturePayments from './components/ContractFuturePayments';
import ContractPaidPayments from './components/ContractPaidPayments';
import { contractPaymentsDetailEndpoint } from './services/contractPaymentsService';
import { ContractPaymentDetail } from './models/contracts';

const ContractPaymentsDetail: React.FC = () => {
  const { id: transactionId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const [contractPayments, setContractPayments] = useState<ContractPaymentDetail>()

  const loadContractPayment = useCallback(async () => {
    const {
      status, data
    } = await callEndpoint(contractPaymentsDetailEndpoint(transactionId, token));

    const response = fetchResponseAdapter(data);

    if (status === 200) {
      setContractPayments(response.data);
    } else {
      setErrorAlert(response.message);
    }
  }, [callEndpoint, transactionId, token]);

  useEffect(() => {
    loadContractPayment().catch(e => setErrorAlert(e.toString()));
  }, [loadContractPayment]);

  const pageName = "Detalle de alquiler";
  const pageParentName = "Pago de alquileres";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!contractPayments || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  const contractDetails = (
    <ContractDetails transactionDetails={contractPayments.transaction} />
  );

  const futurePayments = contractPayments
    .transaction_detail
    .filter(
      (transactionItem) =>
        transactionItem.status.toLowerCase() !== "paid"
    )
    .map((element) => ({ "contractId": contractPayments.transaction.contract_id, ...element }))
  const contractFuturePayments = (
    <ContractFuturePayments transactionItems={futurePayments} />
  );

  const paidPayments = contractPayments
    .transaction_detail
    .filter(
      (transactionItem) =>
        transactionItem.status.toLowerCase() === "paid"
    )
    .map((element) => ({ "contractId": contractPayments.transaction.contract_id, ...element }))

  const contractPaidPayments = (
    <ContractPaidPayments transactionItems={paidPayments} />
  );

  const componentBody = (
    <Row>
      <Col lg="4">
        {/* Contract details */}
        {contractDetails}
      </Col>
      <Col lg="8">
        <Row>
          <Col lg="12">
            {/* Contract Future payments */}
            {contractFuturePayments}
          </Col>
          <Col lg="12">
            {/* Contract Paid payments */}
            {contractPaidPayments}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      {componentBody}
    </AdminPage>
  );
};

export default ContractPaymentsDetail;