import React from 'react';
import { TransactionDetailItem } from '../models/contracts';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';

export type ContractPaidPaymentsProps = {
  transactionItems: Array<TransactionDetailItem>;
};

const ContractPaidPayments: React.FC<ContractPaidPaymentsProps> = ({
  transactionItems,
}) => {
  const itemsMapped = transactionItems.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: 'bg-success',
    href: `/contracts/${item.contractId}/quota/${item.id}`,
  }));
  return (
    <PaymentsDetailsTable
      title="Detalle de cuotas pagadas"
      items={itemsMapped}
    />
  );
};

export default ContractPaidPayments;
