import { Controller } from 'react-hook-form';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';

import TimelineHeader from '../../components/headers/TimelineHeader';
import Select from 'react-select';
import useTenant from './hooks/useTenant';

const TenantCreate = () => {
  const {
    handleSubmit,
    doCreate,
    error,
    control,
    errors,
    propersOptions,
    setValue,
    isLoading,
    phone_config
  } = useTenant();

  return (
    <>
      <TimelineHeader name="Crear inquilino" parentName="Lista de inquilinos" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <Form role="form" onSubmit={handleSubmit(doCreate)}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Crear inquilino</h3>
                </CardHeader>
                <CardBody>
                  <Col md="6">
                    {/* first_name */}
                    <Row>
                      <Col md="12">
                        {error && (
                          <UncontrolledAlert color="danger" fade={false}>
                            <span className="alert-inner--text">
                              <strong> Ops! </strong>
                              {error}
                            </span>
                          </UncontrolledAlert>
                        )}
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first_name"
                          >
                            Nombres
                          </label>
                          <Controller
                            name="first_name"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.first_name?.message && 'is-invalid'
                                }
                                {...field}
                                id="first_name"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.first_name?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* last_name */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="last_name"
                          >
                            Apellidos
                          </label>
                          <Controller
                            name="last_name"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.last_name?.message && 'is-invalid'
                                }
                                {...field}
                                id="last_name"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.last_name?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* email */}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Correo electrónico
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.email?.message && 'is-invalid'
                                }
                                {...field}
                                id="email"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.email?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="phone">
                            Teléfono
                          </label>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    { phone_config?.area_code }
                                  </span>
                                </div>
                                <Input
                                  className={
                                    errors.phone?.message && 'is-invalid'
                                  }
                                  {...field}
                                  maxLength={ phone_config?.phone_length ?? 0}
                                  id="phone"
                                />
                              </div>
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.phone?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="proper_id"
                          >
                            Asignar proper
                          </label>
                          <Controller
                            name="proper_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder={'Selecciona un proper'}
                                options={propersOptions}
                                onChange={(selected) => {
                                  setValue('proper_id', selected?.value);
                                }}
                                ref={field.ref}
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.proper_id?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
                <CardFooter>
                  <Button color={'primary'} disabled={isLoading}>
                    Crear
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TenantCreate;
