import { Coordinate, GeoJsonPolygons } from '../../../models/mapViewModel';
import {
  ContainedPolygons,
  PolygonsApi,
  PolygonsForAddressMapBuilding,
  PolygonsForMapBuilding,
} from '../models/polygonsModel';

export const buildMapPolygonsFromGeoJsonAdapter = (
  polygonsApi: PolygonsApi[], //GeoJsonPolygons[]
): PolygonsForMapBuilding[] => {
  //Coordinate[][]
  // let response: Coordinate[][] = [];
  const polygonsAdapted: PolygonsForMapBuilding[] = polygonsApi.map(
    (polygon) => {
      //Coordinate[][]

      const geoJsonPolygon = polygon.geo_points;
      const coordinates = geoJsonPolygon.coordinates[0]; // 0 => [[lng,lat], [lng,lat]]

      const innerCoordinates: Coordinate[] = coordinates.map(
        (innerCoordinate) => {
          const [lng, lat] = innerCoordinate; // => [lng,lat]
          const cr: Coordinate = { lat, lng };
          return cr;
        },
      );
      return {
        id: polygon.id,
        polygon: innerCoordinates,
      };
    },
  );
  return polygonsAdapted;
};

export const buildMapAddressPolygonAdapter = (
  polygon: PolygonsApi, //GeoJsonPolygons[]
  force_center?: Coordinate,
): PolygonsForAddressMapBuilding => {
  //Coordinate[][]
  // let response: Coordinate[][] = [];
  //Coordinate[][]
  const geoJsonPolygon = polygon.geo_points;
  const coordinates = geoJsonPolygon.coordinates[0]; // 0 => [[lng,lat], [lng,lat]]
  const innerCoordinates: Coordinate[] = coordinates.map((innerCoordinate) => {
    const [lng, lat] = innerCoordinate; // => [lng,lat]
    const cr: Coordinate = { lat, lng };
    return cr;
  });
  return {
    id: polygon.id,
    polygon: innerCoordinates,
    polygon_type: polygon.display_info.polygon_type,
    center: force_center
      ? force_center
      : {
          lat: polygon.display_info.center.lat ?? 0,
          lng: polygon.display_info.center.lng ?? 0,
        },
    zoom: polygon.display_info.zoom,
  };
};
