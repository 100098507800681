import { Card, CardBody, CardHeader } from 'reactstrap';
import { TimelineItem } from '../models/timelineModel';

interface Props {
  data: TimelineItem[];
  header?: string;
}

const Timeline = (props: Props) => {
  const { data, header = 'Operaciones sobre oferta' } = props;
  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">{header}</h5>
      </CardHeader>
      <CardBody>
        {data &&
          data.length > 0 &&
          data.map((timelineItem) => (
            <div
              className="timeline timeline-one-side"
              data-timeline-axis-style="dashed"
              data-timeline-content="axis"
              key={timelineItem.time}
            >
              <div className="timeline-block">
                <span className="timeline-step badge-success">
                  <i className="ni ni-bell-55" />
                </span>
                <div className="timeline-content">
                  <div className="d-flex justify-content-between pt-1">
                    <div>
                      <span className="text-muted text-sm font-weight-bold">
                        {timelineItem.main_message}
                      </span>
                    </div>
                    <div className="text-right">
                      <small className="text-muted">
                        <i className="fas fa-clock mr-1" />
                        {timelineItem.time}
                      </small>
                    </div>
                  </div>
                  <h6 className="text-sm mt-1 mb-0">{timelineItem.message}</h6>
                  <span className="text-sm mt-1 mb-0">
                    {timelineItem.sub_message}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </CardBody>
    </Card>
  );
};

export default Timeline;
