import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, CardBody, ListGroup, Table } from 'reactstrap';
import InvestorDownPaymentQuotasModal from '../../pages/investors/components/InvestorDownPaymentQuotasModal';
import useModalState from '../../hooks/useModalState';
import { useState } from 'react';

interface DisplayTransactionRow {
  id: string;
  title: string;
  value: string;
  status: string;
  customDot?: string;
  href?: string;
  allow_divide_quota?: boolean;
}

interface Props {
  transactionId?: string;
  title: string;
  items: DisplayTransactionRow[];
}

export const PaymentsDetailsTable = ({
  transactionId,
  items = [],
  title = '',
}: Props) => {
  const [isOpenModal, toggleModal] = useModalState();
  const [transactionDetailId, setTransactionDetailId] = useState('');
  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">{title}</h6>
          <div
            style={{
              height: '300px',
              overflow: 'scroll',
            }}
          >
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Concepto</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Monto</th>
                </tr>
              </thead>
              <tbody>
                {items.length > 0 &&
                  items.map((item) => (
                    <tr key={item.title}>
                      <td>{item.title}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i
                            className={`${
                              item.customDot ? item.customDot : 'bg-warning'
                            }`}
                          />
                          {item.status}
                        </Badge>
                      </td>
                      <td>
                        {item.value}
                        <Button
                          onClick={() =>
                            item.href ? navigate(`${item.href}`) : false
                          }
                          color=""
                          size="sm"
                          type="button"
                        >
                          <i className="ni ni-bold-right pt-1" />
                        </Button>
                        {item.allow_divide_quota && (
                          <Button
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={() => {
                              setTransactionDetailId(item.id);
                              toggleModal();
                            }}
                          >
                            <i className="ni ni-money-coins pt-1" /> Dividir en
                            cuotas
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ListGroup>
      </CardBody>
      <InvestorDownPaymentQuotasModal
        isOpen={isOpenModal}
        onToggle={toggleModal}
        headerText={'Dividir prima en cuotas'}
        transactionPreSaleId={transactionId}
        transactionDetailId={transactionDetailId}
      />
    </Card>
  );
};
