import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardFooter,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
} from 'reactstrap';
import { getOfferStatusAdapter } from '../../../adapters/offerAdapter';
import ProfileAvatarGroup from '../../../components/ProfileAvatarGroup';
import { LandlordOffer } from '../models/propertyModel';
import { useNavigate } from 'react-router-dom';
import VisitMarketplaceTypeBadge from '../../../components/VisitMarketplaceTypeBadge';

interface Props {
  success?: boolean;
  offerData: LandlordOffer;
}

export const OfferCard = ({ success = false, offerData }: Props) => {
  const navigate = useNavigate();

  const getAlertClass = (offerStatus: string) => {
    switch (offerStatus) {
      case 'accepted':
      case 'finished':
        return 'alert-success';
      case 'rejected':
      case 'inactive':
        return 'alert-danger';
      default:
        return 'alert-default';
    }
  };

  return (
    <>
      <Card className={`p-1 ${success && 'bg-default'}`}>
        <CardBody
          className="blockquote mb-0"
          onClick={() => navigate(`/offer/${offerData.id}/detail`)}
          style={{ cursor: 'pointer', textDecoration: 'none' }}
        >
          <ListGroup className="list my--4" flush>
            <ListGroupItem className={`px-0 ${success && 'bg-default'} `}>
              <Row className="align-items-center">
                <ProfileAvatarGroup
                  code={offerData.tenant_id}
                  name={offerData.tenant_name}
                  avatar={offerData.tenant_avatar}
                  avatar_size="avatar-xl"
                />
                <Col className={`col-auto ${success && 'text-white'}`}>
                  <Row>
                    <Col>
                      <p className="mb-0">
                        {offerData.tenant_name}{' '}
                        <a
                          href={`/tenant/${offerData.tenant_id}/profile`}
                          target="_blank"
                          className="fas fa-eye"
                        />
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Badge color="success">{offerData.offer}</Badge>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="mb-0">
                        <span className="font-weight-bold">A partir de:</span>
                        &nbsp;{offerData.estimate_moving_at}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="mb-0">
                        <span className="font-weight-bold">Periodo:</span>
                        &nbsp;{offerData.period}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <VisitMarketplaceTypeBadge
                        marketplace_type={offerData.marketplace_type}
                        customClassname="badge-sm"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
        <CardFooter className={`p-1 ${success && 'bg-default'}`}>
          <Alert
            className={`text-center ${getAlertClass(offerData.status)} m-0`}
          >
            <strong>{getOfferStatusAdapter(offerData.status)}</strong>
          </Alert>
        </CardFooter>
      </Card>
    </>
  );
};
