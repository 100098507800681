// lib
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoadingCard from '../components/LoadingCard';
// import { getAuth } from "../helpers/authHelper";
import { loginAction, logoutAction } from '../redux/features/auth';
// pages
import AuthRoutes from './AuthRoutes';
import PrivateRoutes from './PrivateRoutes';
import PublicRoute from './PublicRoutes';
import SiteRoutes from './SiteRoutes';
import useAuth from '../hooks/useAuth';

const AppRouter = () => {
  const { getAuth, unsetAuth } = useAuth();
  const [checking, setChecking] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const authDataStorage = getAuth();
    console.log({ authDataStorage });
    if (authDataStorage) {
      dispatch(loginAction(authDataStorage));
      setChecking(false);
    } else {
      // console.info('logging out not authorized user.');
      dispatch(logoutAction({}));
      unsetAuth();
      setChecking(false);
    }
  }, [dispatch, setChecking, getAuth]);

  if (checking) {
    return <LoadingCard name="" parent_name="" />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route
          path="/auth/*"
          element={
            <PublicRoute>
              <AuthRoutes />
            </PublicRoute>
          }
        />

        {/* Private routes */}
        <Route
          path="/*"
          element={
            <PrivateRoutes>
              <SiteRoutes />
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
