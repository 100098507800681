import axios from 'axios';
import { LandlordRegistrationForm } from '../models/landlordModel';

const loadAbort = () => {
  return new AbortController();
};

export const getLandlordEndpoint = (landlordId: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/landlord/${landlordId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getLandlordsEndpoint = (query: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/landlord${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createLandlordEndpoint = (
  form: LandlordRegistrationForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/user/v1/admin/landlord/create`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const updateLandlordEndpoint = (
  landlordId: string,
  payload: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/user/v1/admin/landlord/${landlordId}`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const updateLandlordBankInfoEndpoint = (
  landlordId: string,
  payload: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/user/v1/admin/landlord/${landlordId}/bank-info`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};